import { Button } from "primereact/button";
import { truncateFileName } from "../../util/helper";
import { RFIRequestLoader } from "../Loaders/RFILoader";

export default function ViewRFIRequest({ rfi, attachments, isPending }) {
  if (isPending) {
    return <RFIRequestLoader />;
  }

  if (!isPending) {
    return (
      <>
        <div>
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="subject">
                  Subject
                </label>
                <div id="subject" className="flex min-h-3rem align-items-start">
                  {rfi.subject}
                </div>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="question">
                  Question
                </label>
                <div
                  id="question"
                  className="flex min-h-3rem align-items-start py-2"
                >
                  {rfi.rfi_body}
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="attachments">
                  Attachments
                </label>
                {attachments && attachments.length > 0 ? (
                  <div>
                    {attachments.map((attachment) => (
                      <div key={attachment.filename}>
                        <Button
                          label={truncateFileName(attachment.filename, 14)}
                          severity="secondary"
                          icon="pi pi-external-link"
                          className="p-mr-2 p-mb-2 mt-3"
                          onClick={() => window.open(attachment.url, "_blank")}
                          outlined
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>No attachments</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
