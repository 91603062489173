import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { getUserData } from "../../util/storage";
import { Dropdown } from "primereact/dropdown";
import { useQuery } from "@tanstack/react-query";
import { fetchEntities } from "../../api/entitiesAPI";
import { Calendar } from "primereact/calendar";

export default function MobileSubmittalWorkflow({
  workflow,
  setWorkflow,
  editMode,
}) {
  const isProcoreUser = getUserData()["is_procore_user"];

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["entities"],
    queryFn: fetchEntities,
    staleTime: 5 * 60 * 1000,
  });

  const addWorkflowStep = () => {
    let _workflow = [...workflow];
    _workflow.push({
      user_ids: [],
      approver_type: "Submitter",
      due_date: undefined,
      response: {},
    });

    setWorkflow(_workflow);
  };

  const removeWorkflowStep = (rowData, index) => {
    let _workflow = [...workflow];
    _workflow.splice(index, 1);

    setWorkflow(_workflow);
  };

  const onInputChanged = (e, name, index) => {
    const val = (e.target && e.target.value) || "";

    let _workflow = [...workflow];
    _workflow[index][`${name}`] = val;

    setWorkflow(_workflow);
  };

  const approversBodyTemplate = (item, index) => {
    return (
      <>
        <MultiSelect
          id={`approver-${index}`}
          value={item.user_ids}
          onChange={(e) => onInputChanged(e, "user_ids", index)}
          options={users}
          optionLabel="name"
          optionValue="id"
          placeholder="Select a Person"
          filter
          className="w-full"
          disabled={
            (editMode && isProcoreUser) ||
            (!isProcoreUser &&
              Object.keys(item.response).includes("status") &&
              item.response?.status !== "Pending" &&
              Object.keys(item.response).length !== 0)
          }
        />
      </>
    );
  };

  const roleBodyTemplate = (item, index) => {
    return (
      <Dropdown
        id={`approver-type-${index}`}
        className="w-full"
        value={item.approver_type}
        onChange={(e) => onInputChanged(e, "approver_type", index)}
        options={[
          { label: "Submitter", value: "Submitter" },
          { label: "Approver", value: "Approver" },
        ]}
        placeholder="Select Role"
        disabled={
          (editMode && isProcoreUser) ||
          (!isProcoreUser &&
            Object.keys(item.response).includes("status") &&
            item.response?.status !== "Pending" &&
            Object.keys(item.response).length !== 0)
        }
      />
    );
  };

  const dueDateBodyTemplate = (item, index) => {
    return (
      <Calendar
        id={`due-date-${index}`}
        value={item?.due_date && new Date(item.due_date)}
        onChange={(e) => onInputChanged(e, "due_date", index)}
        showIcon
        placeholder="Select a Date"
        className="w-full"
        disabled={
          (editMode && isProcoreUser) ||
          (!isProcoreUser &&
            Object.keys(item.response).includes("status") &&
            item.response?.status !== "Pending" &&
            Object.keys(item.response).length !== 0)
        }
      />
    );
  };

  const actionBodyTemplate = (item, index) => {
    return (
      <>
        {isProcoreUser
          ? (!item.response?.name || item.response?.name === "Pending") && (
              <Button
                icon="pi pi-times"
                severity="danger"
                label="Remove Step"
                onClick={() => removeWorkflowStep(item, index)}
                disabled={isProcoreUser && editMode}
                className="w-full"
              />
            )
          : (!item.response?.status || item.response?.status === "Pending") && (
              <Button
                icon="pi pi-times"
                severity="danger"
                label="Remove Step"
                onClick={() => removeWorkflowStep(item, index)}
                disabled={
                  (editMode && isProcoreUser) ||
                  (!isProcoreUser &&
                    Object.keys(item.response).includes("status") &&
                    item.response?.status !== "Pending" &&
                    Object.keys(item.response).length !== 0)
                }
                className="w-full"
              />
            )}
      </>
    );
  };

  const workflowItemTemplate = (item, index) => (
    <div
      key={index}
      className="workflow-item bg-gray-100 p-3 mb-3 border-round-md"
    >
      <div className="field">
        <label htmlFor="name" className="font-bold">
          Name
        </label>
        {approversBodyTemplate(item, index)}
      </div>
      <div className="field">
        <label htmlFor="role" className="font-bold">
          Role
        </label>
        {roleBodyTemplate(item, index)}
      </div>
      <div className="field">
        <label htmlFor="due-date" className="font-bold">
          Due Date
        </label>
        {dueDateBodyTemplate(item, index)}
      </div>
      {actionBodyTemplate(item, index)}
    </div>
  );

  return (
    <div className="field sm:hidden">
      <div>
        {workflow.map((item, index) => workflowItemTemplate(item, index))}
        <Button
          className="w-full"
          label="Add Step"
          icon="pi pi-plus"
          onClick={addWorkflowStep}
        />
      </div>
    </div>
  );
}
