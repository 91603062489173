import axios from "axios";
import { getAuthToken } from "../util/auth";
import API_BASE_URL from "../util/apiConfig";

const token = getAuthToken();

const timeZonesClient = axios.create({
  baseURL: `${API_BASE_URL}/timezones`,
  headers: {
    Authorization: "Bearer " + token,
  },
});

timeZonesClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export async function fetchTimeZones({ signal }) {
  const response = await timeZonesClient.get("", { signal });
  return response.data;
}
