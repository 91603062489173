import { useQuery } from "@tanstack/react-query";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { fetchEntities } from "../../api/entitiesAPI";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { getUserData } from "../../util/storage";

export default function SubmittalWorkflow({ workflow, setWorkflow, editMode }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const [isReorderable, setIsReorderable] = useState(true);
  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["entities"],
    queryFn: fetchEntities,
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    workflow.forEach((element) => {
      if (element.response?.status && element.response?.status !== "Pending") {
        setIsReorderable(false);
      }
    });
  }, []);

  const onInputChanged = (e, name, index) => {
    const val = (e.target && e.target.value) || "";

    let _workflow = [...workflow];
    _workflow[index][`${name}`] = val;

    setWorkflow(_workflow);
  };

  const removeWorkflowStep = (rowData, index) => {
    let _workflow = [...workflow];
    _workflow.splice(index, 1);

    setWorkflow(_workflow);
  };

  const addWorkflowStep = () => {
    let _workflow = [...workflow];
    _workflow.push({
      user_ids: [],
      approver_type: "Submitter",
      due_date: undefined,
      response: {},
    });

    setWorkflow(_workflow);
  };

  const approversBodyTemplate = (rowData, options) => {
    return (
      <>
        <MultiSelect
          id={`approver-${options.rowIndex}`}
          value={rowData.user_ids}
          onChange={(e) => onInputChanged(e, "user_ids", options.rowIndex)}
          options={users}
          optionLabel="name"
          optionValue="id"
          placeholder="Select a Person"
          filter
          className="w-full"
          disabled={
            (editMode && isProcoreUser) ||
            (!isProcoreUser &&
              Object.keys(rowData.response).includes("status") &&
              rowData.response?.status !== "Pending" &&
              Object.keys(rowData.response).length !== 0)
          }
        />
      </>
    );
  };

  const roleBodyTemplate = (rowData, options) => {
    return (
      <Dropdown
        id={`approver-type-${options.rowIndex}`}
        value={rowData.approver_type}
        onChange={(e) => onInputChanged(e, "approver_type", options.rowIndex)}
        options={[
          { label: "Submitter", value: "Submitter" },
          { label: "Approver", value: "Approver" },
        ]}
        placeholder="Select Role"
        disabled={
          (editMode && isProcoreUser) ||
          (!isProcoreUser &&
            Object.keys(rowData.response).includes("status") &&
            rowData.response?.status !== "Pending" &&
            Object.keys(rowData.response).length !== 0)
        }
      />
    );
  };

  const dueDateBodyTemplate = (rowData, options) => {
    return (
      <Calendar
        id={`due-date-${options.rowIndex}`}
        value={rowData?.due_date && new Date(rowData.due_date)}
        onChange={(e) => onInputChanged(e, "due_date", options.rowIndex)}
        showIcon
        placeholder="Select a Date"
        disabled={
          (editMode && isProcoreUser) ||
          (!isProcoreUser &&
            Object.keys(rowData.response).includes("status") &&
            rowData.response?.status !== "Pending" &&
            Object.keys(rowData.response).length !== 0)
        }
      />
    );
  };

  const actionBodyTemplate = (rowData, options) => {
    return (
      <>
        {isProcoreUser
          ? (!rowData.response?.name ||
              rowData.response?.name === "Pending") && (
              <Button
                icon="pi pi-times"
                rounded
                text
                severity="danger"
                onClick={() => removeWorkflowStep(rowData, options.rowIndex)}
                disabled={isProcoreUser && editMode}
              />
            )
          : (!rowData.response?.status ||
              rowData.response?.status === "Pending") && (
              <Button
                icon="pi pi-times"
                rounded
                text
                severity="danger"
                onClick={() => removeWorkflowStep(rowData, options.rowIndex)}
                disabled={
                  (editMode && isProcoreUser) ||
                  (!isProcoreUser &&
                    Object.keys(rowData.response).includes("status") &&
                    rowData.response?.status !== "Pending" &&
                    Object.keys(rowData.response).length !== 0)
                }
              />
            )}
      </>
    );
  };

  const footer = () => {
    return (
      <>
        <Button
          label="Add Step"
          onClick={addWorkflowStep}
          disabled={isProcoreUser && editMode}
        />
      </>
    );
  };

  return (
    <div className="card sm:block hidden">
      <DataTable
        value={workflow}
        reorderableRows={isReorderable && !(isProcoreUser && editMode)}
        onRowReorder={(e) => setWorkflow(e.value)}
        tableStyle={{ minWidth: "50rem" }}
        footer={footer}
      >
        <Column
          rowReorder={isReorderable && !(isProcoreUser && editMode)}
          style={{ width: "3rem" }}
        />
        <Column
          key="user_ids"
          columnKey="user_ids"
          header="Reviewer(s)"
          body={(data, options) => approversBodyTemplate(data, options)}
          style={{ width: "25rem", maxWidth: "25rem" }}
        />
        <Column
          key="approver_type"
          columnKey="approver_type"
          header="Role"
          body={(data, options) => roleBodyTemplate(data, options)}
        />
        <Column
          key="due_date"
          columnKey="due_date"
          header="Due Date"
          body={(data, options) => dueDateBodyTemplate(data, options)}
          style={{ minWidth: "13rem" }}
        />
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "3rem" }}
        ></Column>
      </DataTable>
    </div>
  );
}
