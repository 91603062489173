import { useState, useEffect, useRef, useContext } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";

import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import { assignTask, createTask, updateTask } from "../../api/tasksAPI";
import { uploadFiles } from "../../api/filesAPI";
import { queryClient } from "../../api/requestProcessor";
import CustomFileUpload from "../FileUpload/FileUpload";
import TaskAttachments from "../Attachments";

import "./CreateTask.css";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import { fetchContacts } from "../../api/contactsAPI";

export default function CreateTask({
  taskDialog,
  hideDialog,
  task,
  onInputChange,
  editTaskMode,
}) {
  const [files, setFiles] = useState([]);
  const [label, setLabel] = useState("Save");
  const [attachments, setAttachments] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);

  const filterRef = useRef();

  const { toast } = useContext(AppContext);

  useEffect(() => {
    if (task.attachments) {
      setAttachments(task.attachments);
    } else {
      setAttachments([]);
    }
  }, [task.attachments]);

  const { data: contacts } = useQuery({
    queryKey: ["contacts"],
    queryFn: fetchContacts,
  });

  const { mutate: createTaskMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createTask,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["tasks"] });
        queryClient.invalidateQueries({ queryKey: ["openItems"] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Task Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutate: updateTaskMutation, isPending: isPendingUpdate } =
    useMutation({
      mutationFn: updateTask,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["tasks"] });
        queryClient.invalidateQueries({ queryKey: ["openItems"] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Task Updated",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutate: assignTaskMutation, isPending: isPendingAssign } =
    useMutation({
      mutationFn: assignTask,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["tasks"] });
        queryClient.invalidateQueries({ queryKey: ["openItems"] });
        hideDialog();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Task Assigned",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingUpload) {
      setLabel("Uploading Files");
    } else if (isPendingCreation || isPendingUpdate) {
      setLabel("Saving Changes");
    } else {
      setLabel("Save");
    }
  }, [isPendingUpload, isPendingCreation, isPendingUpdate]);

  const saveTask = async () => {
    let updatedAttachments = [...attachments];

    if (files.length > 0) {
      const uploadPromises = files.map(async (file) => {
        return await uploadFilesMutation({
          files: [file],
        });
      });

      const responses = await Promise.all(uploadPromises);
      updatedAttachments = [...responses, ...attachments];
    }
    if (editTaskMode) {
      updateTaskMutation({
        taskId: task["_id"],
        taskData: task,
        attachments: updatedAttachments,
      });
    } else
      createTaskMutation({ taskData: task, attachments: updatedAttachments });
  };

  const onFileChange = (files) => {
    setFiles(files);
  };

  const handleAssignTask = () => {
    assignTaskMutation({
      taskId: task._id,
      taskData: { assignee: task.assignee },
    });
  };

  const taskDialogFooter = (
    <>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      {editTaskMode && (
        <Button
          label={isPendingAssign ? "Assigning" : "Assign"}
          icon="pi pi-user-edit"
          onClick={handleAssignTask}
          loading={isPendingAssign}
          disabled={isPendingAssign}
        />
      )}
      <Button
        className="mt-2"
        label={label}
        icon="pi pi-check"
        onClick={saveTask}
        loading={isPendingCreation || isPendingUpdate || isPendingUpload}
        disabled={isPendingCreation || isPendingUpdate || isPendingUpload}
      />
    </>
  );

  return (
    <Dialog
      visible={taskDialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Task Details"
      modal
      className="p-fluid mx-2"
      footer={taskDialogFooter}
      onHide={hideDialog}
    >
      <div className="field">
        <label htmlFor="assignees" className="font-bold">
          Assignee
        </label>
        <Dropdown
          value={task.assignee}
          id="assignee"
          options={contacts}
          optionLabel="email"
          optionValue="email"
          onChange={(e) => onInputChange(e, "assignee")}
          placeholder="Assignee"
          className="w-full mb-5"
          filter
          ref={filterRef}
          panelClassName="max-w-16rem"
        />
      </div>

      <div className="field">
        <label htmlFor="subject" className="font-bold">
          Subject
        </label>
        <InputText
          id="subject"
          value={task.subject}
          onChange={(e) => onInputChange(e, "subject")}
          required
        />
      </div>
      <div className="field">
        <label htmlFor="task_body" className="font-bold">
          Task Body
        </label>
        <InputTextarea
          id="task_body"
          value={task.task_body}
          onChange={(e) => onInputChange(e, "task_body")}
          required
          rows={3}
          cols={20}
        />
      </div>
      {editTaskMode && (
        <div className="field">
          <label htmlFor="status" className="font-bold">
            Status
          </label>
          <Dropdown
            id="status"
            value={task.status}
            onChange={(e) => onInputChange(e, "status")}
            options={[
              { label: "Open", value: "Open" },
              { label: "Closed", value: "Closed" },
              { label: "Void", value: "Void" },
              { label: "Overdue", value: "Overdue" },
            ]}
            placeholder="Select Status"
          />
        </div>
      )}
      {attachments && attachments.length ? (
        <div className="field">
          <label htmlFor="task-attachments" className="font-bold">
            Attachments
          </label>
          <TaskAttachments
            id={"task-attachments"}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </div>
      ) : (
        <></>
      )}
      <CustomFileUpload
        accept=""
        onFileChange={onFileChange}
        reset={uploadReset}
        setReset={setUploadReset}
      />
    </Dialog>
  );
}
