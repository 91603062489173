import { Fieldset } from "primereact/fieldset";
import RFIRequest from "../components/RFIs/RFIRequest";
import { useContext, useEffect, useState } from "react";
import RFIGeneralInformation from "../components/RFIs/RFIGeneralInformation";
import { AppContext } from "../context/AppProvider";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getRfi, updateRfi } from "../api/rfisAPI";
import { queryClient } from "../api/requestProcessor";
import { getErrorMessage } from "../util/helper";
import { uploadFiles } from "../api/filesAPI";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";

export default function EditRFIPage() {
  const [rfi, setRfi] = useState({
    subject: "",
    rfi_body: "",
    attachments: [],
    number: 0,
  });
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [label, setLabel] = useState("Save");
  const [attachments, setAttachments] = useState([]);

  const { toast, user } = useContext(AppContext);
  const navigate = useNavigate();
  const params = useParams();

  const isProcoreUser = user.is_procore_user;

  const { data: rfiData, isFetching: isPendingRfi } = useQuery({
    queryKey: ["rfis", params.rfiId],
    queryFn: ({ signal }) => getRfi({ signal, rfiId: params.rfiId }),
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: updateRfiMutation, isPending: isPendingUpdate } = useMutation(
    {
      mutationFn: updateRfi,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["rfis"] });
        queryClient.invalidateQueries({ queryKey: ["rfis", params.rfiId] });
        navigate("/rfis");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "RFI Updated",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    }
  );

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingUpload) {
      setLabel("Uploading Files");
    } else if (isPendingUpdate) {
      setLabel("Saving Changes");
    } else {
      setLabel("Save and Send Emails");
    }
  }, [isPendingUpload, isPendingUpdate, isProcoreUser]);

  useEffect(() => {
    if (rfiData) {
      if (isProcoreUser) {
        setRfi({ ...rfiData, rfi_body: rfiData.questions[0].plain_text_body });
        setAttachments(rfiData.questions[0].attachments);
      } else {
        setRfi(rfiData);
        setAttachments(rfiData.attachments);
      }
    }
  }, [rfiData]);

  const cancelRfiCreation = () => {
    navigate("/rfis");
  };

  const printErrorMessage = (errorMessage) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMessage,
      life: 3000,
    });
  };

  const checkValidation = () => {
    if (!rfi.subject) {
      printErrorMessage("RFI Subject area is required!");
      return false;
    } else if (!isProcoreUser && !rfi.rfi_body) {
      printErrorMessage("RFI Body area is required!");
      return false;
    }
    return true;
  };

  const handleCreateRfi = async () => {
    if (checkValidation()) {
      let updatedAttachments = [...attachments];

      if (files.length > 0) {
        const uploadPromises = files.map(async (file) => {
          return await uploadFilesMutation({
            files: [file],
          });
        });

        const responses = await Promise.all(uploadPromises);
        updatedAttachments = [...responses, ...attachments];
      }

      const rfiData = { ...rfi };

      updateRfiMutation({
        rfiId: rfiData.id,
        rfiData: rfiData,
        attachments: updatedAttachments,
      });
    }
  };

  return (
    <>
      <div className="card">
        <Fieldset legend={`Edit RFI #${rfi.number}: ${rfi.subject}`}>
          <div>
            <RFIRequest
              rfi={rfi}
              setRfi={setRfi}
              setFiles={setFiles}
              uploadReset={uploadReset}
              setUploadReset={setUploadReset}
              isProcoreUser={isProcoreUser}
              attachments={attachments}
              setAttachments={setAttachments}
              isPending={isPendingRfi}
            />
            <RFIGeneralInformation
              rfi={rfi}
              setRfi={setRfi}
              editMode={true}
              isPending={isPendingRfi}
            />
          </div>

          <div className="flex justify-content-end gap-2 mt-2">
            <Button
              label="Cancel"
              icon="pi pi-times"
              outlined
              onClick={cancelRfiCreation}
            />
            <Button
              label={label}
              icon="pi pi-check"
              onClick={handleCreateRfi}
              loading={isPendingUpdate || isPendingUpload}
              disabled={isPendingUpdate || isPendingUpload}
              className="right-0"
            />
          </div>
        </Fieldset>
      </div>
    </>
  );
}
