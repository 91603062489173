import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import FilterSidebar from "../FilterSidebar";
import { Dropdown } from "primereact/dropdown";
import { updateTaskStatus } from "../../api/tasksAPI";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../api/requestProcessor";
import { AppContext } from "../../context/AppProvider";
import { getErrorMessage } from "../../util/helper";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function TaskTable({
  dt,
  selectedTasks,
  setSelectedTasks,
  tasks,
  isPending,
  editTask,
  confirmDeleteTask,
  exportCSV,
  openNew,
  setQueryParams,
  total,
  initialFilters,
  openTaskReminderDialog,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [filterSidebarVisible, setFilterSidebarVisible] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const [externalInvalidation, setExternalInvalidation] = useState(0);

  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });
  const [numOfSelectedFilters, setNumOfSelectedFilters] = useState(0);

  const { toast, user } = useContext(AppContext);

  const { mutate: updateStatusMutation } = useMutation({
    mutationFn: updateTaskStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      queryClient.invalidateQueries({
        queryKey: ["filterOptions"],
      });
      setExternalInvalidation((prev) => prev + 1);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Status Updated",
        life: 3000,
      });
    },
    onError: (error) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: getErrorMessage(error),
        life: 3000,
      });
    },
  });

  useEffect(() => {
    setQueryParams(mergeAllQueries());
  }, [lazyState, setQueryParams]);

  useEffect(() => {
    setLazyState({ ...lazyState, first: 0, page: 0 });
  }, [filterQuery, setQueryParams]);

  const mergeAllQueries = () => {
    const pageQuery = { page: lazyState.page + 1, per_page: lazyState.rows };
    const searchQuery = { search: searchInput };
    return { ...pageQuery, ...searchQuery, ...filterQuery };
  };

  const onSearchInputChange = (e) => {
    const val = (e.target && e.target.value) || "";
    setSearchInput(val);
  };

  const handleSearch = (e, isFromButton) => {
    if (e.key === "Enter" || isFromButton) {
      setLazyState({ ...lazyState, page: 0, first: 0 });
    }
  };

  const onResetSearch = (e) => {
    const allQueries = mergeAllQueries();
    allQueries.search = "";
    setQueryParams(allQueries);
    setSearchInput("");
  };

  const onPage = (e) => {
    setLazyState(e);
  };

  const exportPdf = () => {
    const exportData = selectedTasks.length > 0 ? selectedTasks : tasks;

    if (exportData.length === 0) {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "No tasks available for export.",
        life: 3000,
      });
      return;
    }

    const doc = new jsPDF({ orientation: "landscape" });

    const exportColumns = [
      { header: "Assignee", dataKey: "assignee" },
      { header: "Subject", dataKey: "subject" },
      { header: "Task Body", dataKey: "task_body" },
      { header: "Status", dataKey: "status" },
    ];

    const exportRows = exportData.map((task) => ({
      assignee: convertSpecialChars(task.assignee),
      subject: convertSpecialChars(task.subject),
      task_body: convertSpecialChars(task.task_body),
      status: convertSpecialChars(task.status),
    }));

    doc.autoTable({
      head: [exportColumns.map((col) => col.header)],
      body: exportRows,
      columns: exportColumns,
      styles: {
        cellPadding: 2,
        fontSize: 8,
        overflow: "linebreak",
        valign: "top",
      },
      columnStyles: {
        assignee: { cellWidth: "wrap" },
        subject: { cellWidth: "wrap" },
        task_body: { cellWidth: "wrap" },
        status: { cellWidth: 20 },
      },
      didParseCell: function (data) {
        data.cell.styles.minCellHeight = 10;
      },
    });

    doc.save("nicky_ai_tasks.pdf");
  };

  function convertSpecialChars(str) {
    const charMap = {
      ı: "i",
      İ: "I",
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
    };

    return str.replace(/[ıİğĞüÜşŞöÖçÇ]/g, (char) => charMap[char] || char);
  }

  const renderEdit = (task) => {
    if (task.is_assigned) {
      if (user.is_procore_user) {
        return task.task_manager.id === user.procore_user.id.toString();
      } else {
        return task.task_manager.id === user.id;
      }
    } else {
      return true;
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div>
          {user.role > 1 && (
            <Button
              label="New"
              icon="pi pi-plus"
              severity="success"
              onClick={openNew}
              className="mr-2"
            />
          )}
          <Button
            label="Export CSV"
            icon="pi pi-upload"
            className="p-button-help mr-2"
            onClick={() => exportCSV(selectedTasks.length > 0 ? true : false)}
          />
          <Button
            label="Export PDF"
            icon="pi pi-file-pdf"
            className="p-button-help mr-2"
            onClick={exportPdf}
            data-pr-tooltip="PDF"
            outlined
          />
          {user.role > 1 && (
            <Button
              label={
                numOfSelectedFilters === 0
                  ? "Filter"
                  : `Filter (${numOfSelectedFilters})`
              }
              icon="pi pi-filter"
              onClick={() => setFilterSidebarVisible(true)}
            />
          )}
        </div>
        <div>
          <span className="p-input-icon-right">
            {searchInput && (
              <i
                className="pi pi-times mr-1 cursor-pointer"
                onClick={onResetSearch}
              />
            )}
            <InputText
              value={searchInput}
              onChange={onSearchInputChange}
              placeholder="Keyword Search"
              className="mr-1"
              onKeyDown={(e) => handleSearch(e, false)}
            />
          </span>
          <Button icon="pi pi-search" onClick={(e) => handleSearch(e, true)} />
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const onStatusChanged = (e, task) => {
    updateStatusMutation({ taskId: task._id, status: e.target.value });
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Dropdown
        id="status"
        value={rowData.status}
        onChange={(e) => onStatusChanged(e, rowData)}
        options={[
          { label: "🔴 Open", value: "Open" },
          { label: "🟢 Closed", value: "Closed" },
          { label: "✖️ Void", value: "Void" },
          { label: "🟡 Overdue", value: "Overdue" },
        ]}
        placeholder="Select Status"
        style={{ minWidth: "10rem" }}
        disabled={!renderEdit(rowData)}
      />
    );
  };

  const assigneeTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">
        {rowData.is_assigned ? (
          <span className="flex align-items-center">
            <i className="pi pi-user-plus text-green-500 mr-2" />
            <span className="font-bold text-green-600">{rowData.assignee}</span>
          </span>
        ) : (
          <span className="flex align-items-center">
            <i className="pi pi-user text-gray-500 mr-2" />
            <span className="text-gray-600">{rowData.assignee}</span>
          </span>
        )}
      </div>
    );
  };

  const goToEmail = (rowData) => {
    window.open(rowData.email_url, "_blank");
  };

  const addReminder = (rowData) => {
    openTaskReminderDialog(rowData);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {user.role > 1 && (
          <>
            {rowData.email_url && (
              <Button
                icon="pi pi-envelope"
                rounded
                className="mr-2"
                onClick={() => goToEmail(rowData)}
                text
              />
            )}
            {renderEdit(rowData) && (
              <Button
                icon="pi pi-pencil"
                rounded
                className="mr-2"
                onClick={() => editTask(rowData)}
                text
              />
            )}
            {renderEdit(rowData) && (
              <Button
                icon="pi pi-trash"
                rounded
                text
                severity="danger"
                onClick={() => confirmDeleteTask(rowData)}
              />
            )}
            <Button
              icon="pi pi-bell"
              rounded
              text
              onClick={() => addReminder(rowData)}
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      <DataTable
        ref={dt}
        selection={selectedTasks}
        onSelectionChange={(e) => setSelectedTasks(e.value)}
        dataKey="_id"
        value={tasks}
        paginator
        showGridlines
        lazy
        onPage={onPage}
        first={lazyState.first}
        rows={10}
        totalRecords={total}
        loading={isPending}
        header={header}
        emptyMessage="No tasks found."
        className="hidden sm:block"
        rowClassName={(rowData) => ({
          "bg-green-50": rowData.is_assigned,
        })}
      >
        <Column selectionMode="multiple" exportable={false}></Column>
        <Column
          field="assignee"
          header="Assignee"
          style={{ minWidth: "12rem" }}
          body={assigneeTemplate}
        />
        <Column
          header="Subject"
          field="subject"
          style={{ minWidth: "12rem" }}
        />
        <Column
          header="Task Body"
          field="task_body"
          style={{ minWidth: "12rem" }}
        />
        <Column
          field="status"
          header="Status"
          style={{ minWidth: "3rem" }}
          body={statusBodyTemplate}
        />
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "18rem" }}
        ></Column>
      </DataTable>
      <FilterSidebar
        isVisible={filterSidebarVisible}
        setIsVisible={setFilterSidebarVisible}
        setFilterQuery={setFilterQuery}
        initialFilters={initialFilters}
        pageType="task"
        setNumOfSelectedFilters={setNumOfSelectedFilters}
        externalInvalidation={externalInvalidation}
      />
    </>
  );
}
