import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppProvider";
import { useQuery } from "@tanstack/react-query";
import { getRfi } from "../api/rfisAPI";
import { useParams } from "react-router-dom";
import ViewRFIRequest from "../components/RFIs/ViewRFIRequest";
import ViewRFIGeneralInformation from "../components/RFIs/ViewRFIGeneralInformation";
import ViewRFIResponses from "../components/RFIs/ViewRFIResponses";
import { Fieldset } from "primereact/fieldset";

export default function ViewRFIPage() {
  const [rfi, setRfi] = useState({
    subject: "",
    rfi_body: "",
    attachments: [],
    number: 0,
  });
  const [attachments, setAttachments] = useState([]);
  const { user } = useContext(AppContext);
  const params = useParams();

  const isProcoreUser = user.is_procore_user;

  const { data: rfiData, isFetching: isPendingRfi } = useQuery({
    queryKey: ["rfis", params.rfiId],
    queryFn: ({ signal }) => getRfi({ signal, rfiId: params.rfiId }),
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (rfiData) {
      if (isProcoreUser) {
        setRfi({ ...rfiData, rfi_body: rfiData.questions[0].plain_text_body });
        setAttachments(rfiData.questions[0].attachments);
      } else {
        setRfi(rfiData);
        setAttachments(rfiData.attachments);
      }
    }
  }, [rfiData]);

  return (
    <Fieldset legend={`RFI #${rfi.number}: ${rfi.subject}`}>
      <div className="flex flex-column gap-4">
        <div className="card">
          <ViewRFIRequest
            rfi={rfi}
            attachments={attachments}
            isPending={isPendingRfi}
          />
        </div>

        <div className="card">
          <p className="font-bold">Responses</p>
          <ViewRFIResponses
            rfi={rfi}
            isProcoreUser={isProcoreUser}
            rfiId={params.rfiId}
            isPending={isPendingRfi}
          />
        </div>

        <div className="card">
          <ViewRFIGeneralInformation
            rfi={rfi}
            isProcoreUser={isProcoreUser}
            isPending={isPendingRfi}
          />
        </div>
      </div>
    </Fieldset>
  );
}
