import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import CustomFileUpload from "../FileUpload/FileUpload";
import Attachments from "../Attachments";
import { RFIRequestLoader } from "../Loaders/RFILoader";

export default function RFIRequest({
  rfi,
  setRfi,
  setFiles,
  uploadReset,
  setUploadReset,
  isProcoreUser,
  attachments,
  setAttachments,
  isPending,
}) {
  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _rfi = { ...rfi };
    _rfi[`${name}`] = val;
    setRfi(_rfi);
  };

  const onFileChange = (files) => {
    setFiles(files);
  };

  if (isPending) {
    return <RFIRequestLoader />;
  }

  return (
    <div className="flex flex-column">
      <div className="grid">
        {/* Subject Row */}
        <div className="col-12">
          <div className="flex flex-column gap-2">
            <label htmlFor="subject">Subject</label>
            <InputText
              id="subject"
              value={rfi.subject}
              onChange={(e) => onInputChange(e, "subject")}
              className="w-full"
            />
          </div>
        </div>

        {/* Question and Attachments Row */}
        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <label htmlFor="question">Question</label>
            <InputTextarea
              id="question"
              value={rfi.rfi_body}
              onChange={(e) => onInputChange(e, "rfi_body")}
              autoResize
              rows={8}
              className="w-full"
            />
          </div>
        </div>

        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <label htmlFor="attachments">Attachments</label>
            <CustomFileUpload
              id="attachments"
              accept=""
              onFileChange={onFileChange}
              reset={uploadReset}
              setReset={setUploadReset}
              className="w-full"
            />
            {attachments && attachments.length > 0 && (
              <div className="mt-2">
                <Attachments
                  id="rfi-attachments"
                  attachments={attachments}
                  setAttachments={setAttachments}
                  isDisabled={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
