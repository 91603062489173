import React, { useContext, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createCompany } from "../../../api/companiesAPI";
import { queryClient } from "../../../api/requestProcessor";
import { AppContext } from "../../../context/AppProvider";
import { getErrorMessage } from "../../../util/helper";
import { fetchTimeZones } from "../../../api/timeZonesAPI";
import { Dropdown } from "primereact/dropdown";

const initialFormData = {
  name: "",
  address: "",
  city: "",
  state_code: "",
  country_code: "",
  zip: "",
  county: "",
  time_zone: "",
  is_active: true,
  phone: "",
  point_of_contact: "",
  company_type: "",
};

const CreateCompanyDialog = ({ visible, onHide }) => {
  const [formData, setFormData] = useState(initialFormData);
  const { toast } = useContext(AppContext);

  const { data: timezones, isFetching: isFetchingTimezones } = useQuery({
    queryKey: ["timezones"],
    queryFn: fetchTimeZones,
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: createCompanyMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createCompany,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["admin-companies"] });
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Company Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = () => {
    createCompanyMutation(formData);
    onHide();
    setFormData(initialFormData);
  };

  const dialogFooter = (
    <div className="flex justify-content-end">
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={onHide}
        className="button-text"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        loading={isPendingCreation}
        onClick={handleSubmit}
      />
    </div>
  );

  return (
    <Dialog
      header="Create New Company"
      visible={visible}
      style={{ width: "50vw" }}
      footer={dialogFooter}
      onHide={onHide}
    >
      <form className="p-fluid">
        <div className="field">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="address">Address</label>
          <InputText
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="city">City</label>
          <InputText
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="state_code">State</label>
          <InputText
            id="state_code"
            name="state_code"
            value={formData.state_code}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="country_code">Country</label>
          <InputText
            id="country_code"
            name="country_code"
            value={formData.country_code}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="zip">Zip</label>
          <InputText
            id="zip"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="county">County</label>
          <InputText
            id="county"
            name="county"
            value={formData.county}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="time_zone">Time Zone</label>
          <Dropdown
            id="time_zone"
            name="time_zone"
            value={formData.time_zone}
            onChange={handleChange}
            options={timezones}
            disabled={isFetchingTimezones}
          />
        </div>
        <div className="field-checkbox">
          <Checkbox
            id="is_active"
            name="is_active"
            checked={formData.is_active}
            onChange={handleChange}
          />
          <label htmlFor="is_active">Active</label>
        </div>
        <div className="field">
          <label htmlFor="phone">Phone</label>
          <InputText
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="point_of_contact">Point of Contact</label>
          <InputText
            id="point_of_contact"
            name="point_of_contact"
            value={formData.point_of_contact}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="company_type">Company Type</label>
          <InputText
            id="company_type"
            name="company_type"
            value={formData.company_type}
            onChange={handleChange}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default CreateCompanyDialog;
