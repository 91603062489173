import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import FilterSidebar from "../FilterSidebar";
import { getUserData } from "../../util/storage";
import { AppContext } from "../../context/AppProvider";

export default function RFITable({
  dt,
  selectedRFIs,
  setSelectedRFIs,
  rfis,
  isPending,
  editRFI,
  confirmDeleteRFI,
  exportCSV,
  openNew,
  createReply,
  setQueryParams,
  total,
  initialFilters,
}) {
  const isProcoreUser = getUserData()["is_procore_user"];

  const [searchInput, setSearchInput] = useState("");
  const [localRFIs, setLocalRFIs] = useState([]);
  const [filterSidebarVisible, setFilterSidebarVisible] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });
  const [numOfSelectedFilters, setNumOfSelectedFilters] = useState(0);

  const { user } = useContext(AppContext);

  useEffect(() => {
    if (rfis) {
      let _rfis = [...rfis];
      _rfis.forEach((element) => {
        let assigneeNames;
        if (element.assignees) {
          assigneeNames = element.assignees
            .map((assignee) => assignee.name)
            .join("\n");
        } else {
          assigneeNames = "";
        }

        let details;

        if (getUserData()["is_procore_user"]) {
          details = `${element.subject} ${element.questions[0].body}`;
        } else {
          details = `${element.subject} ${element.rfi_body}`;
        }

        element.assigneeNames = assigneeNames;
        element.details = details;
      });
      setLocalRFIs(_rfis);
    }
  }, [rfis]);

  useEffect(() => {
    setQueryParams(mergeAllQueries());
  }, [lazyState, setQueryParams]);

  useEffect(() => {
    setLazyState({ ...lazyState, first: 0, page: 0 });
  }, [filterQuery, setQueryParams]);

  const mergeAllQueries = () => {
    const pageQuery = { page: lazyState.page + 1, per_page: lazyState.rows };
    const searchQuery = { search: searchInput };
    return { ...pageQuery, ...searchQuery, ...filterQuery };
  };

  const getSeverity = (status) => {
    switch (status) {
      case "open":
        return "danger";

      case "closed":
        return "success";

      case "draft":
        return "info";

      default:
        return "info";
    }
  };

  const onSearchInputChange = (e) => {
    const val = (e.target && e.target.value) || "";
    setSearchInput(val);
  };

  const handleSearch = (e, isFromButton) => {
    if (e.key === "Enter" || isFromButton) {
      setLazyState({ ...lazyState, page: 0, first: 0 });
    }
  };

  const onResetSearch = (e) => {
    const allQueries = mergeAllQueries();
    allQueries.search = "";
    setQueryParams(allQueries);
    setSearchInput("");
  };

  const onPage = (e) => {
    setLazyState(e);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div>
          {user.role > 1 && (
            <Button
              label="New"
              icon="pi pi-plus"
              severity="success"
              onClick={openNew}
              className="mr-2"
            />
          )}
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help mr-2"
            onClick={() => exportCSV(selectedRFIs.length > 0 ? true : false)}
          />
          {user.role > 1 && (
            <Button
              label={
                numOfSelectedFilters === 0
                  ? "Filter"
                  : `Filter (${numOfSelectedFilters})`
              }
              icon="pi pi-filter"
              onClick={() => setFilterSidebarVisible(true)}
            />
          )}
        </div>
        <div>
          <span className="p-input-icon-right">
            {searchInput && (
              <i
                className="pi pi-times mr-1 cursor-pointer"
                onClick={onResetSearch}
              />
            )}
            <InputText
              value={searchInput}
              onChange={onSearchInputChange}
              placeholder="Keyword Search"
              className="mr-1"
              onKeyDown={(e) => handleSearch(e, false)}
            />
          </span>
          <Button icon="pi pi-search" onClick={(e) => handleSearch(e, true)} />
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const exportRfiDetailTemplate = (rowData) => {
    if (isProcoreUser && rowData.questions[0].body) {
      return `${rowData.subject}\n${rowData.questions[0].body}`;
    } else if (!isProcoreUser && rowData.rfi_body) {
      return `${rowData.subject}\n${rowData.rfi_body}`;
    } else {
      return "";
    }
  };

  const assigneesTemplate = (rowData) => {
    return (
      <>
        {rowData.assignees?.map((assignee, index) => (
          <React.Fragment key={`${rowData.id}-assignee-${index}`}>
            {assignee.name}
            <br />
          </React.Fragment>
        ))}
      </>
    );
  };

  const exportAssigneesTemplate = (rowData) => {
    return rowData.assignees.map((assignee) => assignee.name).join(", ");
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)} />
    );
  };

  const responseDelayTimeTemplate = (rowData) => {
    return rowData.response_delay_time ? rowData.response_delay_time : "0";
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {user.role > 1 && (
          <Button
            icon="pi pi-pencil"
            rounded
            text
            className="mr-1"
            onClick={() => editRFI(rowData)}
          />
        )}
        <Button
          icon="pi pi-eye"
          rounded
          text
          className="mr-1"
          onClick={() => createReply(rowData)}
        />
        {user.role > 1 && (
          <Button
            icon="pi pi-trash"
            rounded
            text
            severity="danger"
            onClick={() => confirmDeleteRFI(rowData)}
          />
        )}
      </>
    );
  };

  return (
    <>
      <DataTable
        ref={dt}
        selection={selectedRFIs}
        onSelectionChange={(e) => setSelectedRFIs(e.value)}
        dataKey="id"
        value={localRFIs}
        paginator
        showGridlines
        lazy
        onPage={onPage}
        first={lazyState.first}
        rows={10}
        totalRecords={total}
        loading={isPending}
        header={header}
        emptyMessage="No RFIs found."
        exportFilename="Nicky AI RFI Log"
        className="hidden sm:block"
      >
        <Column selectionMode="multiple" exportable={false}></Column>
        <Column header="#" field="number" style={{ maxWidth: "4rem" }} />
        <Column
          header="Detail"
          field="subject"
          style={{ minWidth: "24rem" }}
          exportField={exportRfiDetailTemplate}
        />
        <Column
          header="Assignees"
          field="assigneeNames"
          style={{ minWidth: "12rem", whiteSpace: "pre-line" }}
          body={assigneesTemplate}
          exportField={exportAssigneesTemplate}
        />
        <Column
          field="status"
          header="Status"
          style={{ minWidth: "3rem" }}
          body={statusBodyTemplate}
        />
        {!isProcoreUser && (
          <Column
            header="Response Delay Time"
            field="response_delay_time"
            body={responseDelayTimeTemplate}
            exportField={responseDelayTimeTemplate}
          />
        )}
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "12rem" }}
        ></Column>
      </DataTable>
      {filterSidebarVisible && (
        <FilterSidebar
          isVisible={filterSidebarVisible}
          setIsVisible={setFilterSidebarVisible}
          setFilterQuery={setFilterQuery}
          initialFilters={initialFilters}
          pageType="rfi"
          setNumOfSelectedFilters={setNumOfSelectedFilters}
        />
      )}
    </>
  );
}
