import React from "react";
import { getUserData } from "../../util/storage";
import { Button } from "primereact/button";
import { truncateFileName } from "../../util/helper";

export default function SubmittalDetailsBody({ submittal }) {
  const isProcoreUser = getUserData()["is_procore_user"];
  return (
    <div className="flex flex-column mt-2">
      <div className="grid">
        {/* Title Row */}
        <div className="col-12">
          <div className="flex flex-column gap-2">
            <label className="font-semibold" htmlFor="title">
              Title
            </label>
            <div
              id="title"
              className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
            >
              {submittal.title}
            </div>
          </div>
        </div>

        {/* Specification and Number Row */}
        <div className="col-12">
          <div className="grid">
            <div className="col-4">
              <div className="flex flex-column gap-2">
                <label
                  className="font-semibold"
                  htmlFor="specification-section"
                >
                  Spec Section
                </label>
                <div
                  id="specification-section"
                  className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
                >
                  {submittal.specification_section
                    ? `${submittal.specification_section?.description} ${submittal.specification_section?.number}`
                    : "--"}
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="number">
                  Number
                </label>
                <div
                  id="number"
                  className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
                >
                  {submittal.number}
                </div>
              </div>
            </div>

            <div className="col-4">
              <div className="flex flex-column gap-2">
                <label className="font-semibold" htmlFor="revision">
                  Revision
                </label>
                <div
                  id="revision"
                  className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
                >
                  {submittal.revision}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* People Row */}
        <div className="col-12 md:col-4">
          <div className="flex flex-column gap-2">
            <label className="font-semibold" htmlFor="responsible-contractor">
              Responsible Contractor
            </label>
            <div
              id="responsible-contractor"
              className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
            >
              {submittal.responsible_contractor?.name || "--"}
            </div>
          </div>
        </div>

        <div className="col-12 md:col-4">
          <div className="flex flex-column gap-2">
            <label className="font-semibold" htmlFor="received-from">
              Received From
            </label>
            <div
              id="received-from"
              className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
            >
              {submittal.received_from?.name || "--"}
            </div>
          </div>
        </div>

        <div className="col-12 md:col-4">
          <div className="flex flex-column gap-2">
            <label className="font-semibold" htmlFor="submittal-manager">
              Submittal Manager
            </label>
            <div
              id="submittal-manager"
              className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
            >
              {submittal.submittal_manager?.name || "--"}
            </div>
          </div>
        </div>

        {/* Status and Dates Row */}
        <div className="col-12 md:col-4">
          <div className="flex flex-column gap-2">
            <label className="font-semibold" htmlFor="status">
              Status
            </label>
            <div
              id="status"
              className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
            >
              {isProcoreUser ? submittal.status.name : submittal.status}
            </div>
          </div>
        </div>

        <div className="col-12 md:col-4">
          <div className="flex flex-column gap-2">
            <label className="font-semibold" htmlFor="received-date">
              Received Date
            </label>
            <div
              id="received-date"
              className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
            >
              {submittal.received_date || "--"}
            </div>
          </div>
        </div>

        <div className="col-12 md:col-4">
          <div className="flex flex-column gap-2">
            <label className="font-semibold" htmlFor="issue-date">
              Issue Date
            </label>
            <div
              id="issue-date"
              className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
            >
              {submittal.issue_date || "--"}
            </div>
          </div>
        </div>

        {/* Distribution and Ball In Court Row */}
        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <label className="font-semibold" htmlFor="distribution-member-ids">
              Distribution List
            </label>
            <div
              id="distribution-member-ids"
              className="flex flex-wrap gap-2 h-auto min-h-3rem align-items-center border-1 surface-border border-round surface-100 p-3"
            >
              {submittal.distribution_members?.length > 0 ? (
                submittal.distribution_members.map((user, index) => (
                  <span key={user.id}>
                    {user.name}
                    {index !== submittal.distribution_members.length - 1 &&
                      ", "}
                  </span>
                ))
              ) : (
                <span>--</span>
              )}
            </div>
          </div>
        </div>

        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <label className="font-semibold" htmlFor="ball-in-court">
              Ball In Court
            </label>
            <div
              id="ball-in-court"
              className="flex h-auto min-h-3rem align-items-start border-1 surface-border border-round surface-100 p-3"
            >
              {submittal.ball_in_court?.length > 0 ? (
                submittal.ball_in_court.map((user, index) => (
                  <React.Fragment key={user.id}>
                    <span>{user.name}</span>
                    {index !== submittal.ball_in_court.length - 1 && <br />}
                  </React.Fragment>
                ))
              ) : (
                <span>--</span>
              )}
            </div>
          </div>
        </div>

        {/* Description and Attachments Row */}
        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <label className="font-semibold" htmlFor="description">
              Description
            </label>
            <div
              id="description"
              className="flex min-h-3rem align-items-start border-1 surface-border border-round surface-100 p-3"
            >
              {submittal.description || "--"}
            </div>
          </div>
        </div>

        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <label className="font-semibold" htmlFor="attachments">
              Attachments
            </label>
            <div>
              {submittal && submittal.attachments?.length > 0 ? (
                submittal.attachments.map((attachment) => (
                  <Button
                    key={attachment.id}
                    label={truncateFileName(attachment.filename, 14)}
                    severity="secondary"
                    icon="pi pi-external-link"
                    className="p-mr-2 p-mb-2"
                    onClick={() => window.open(attachment.url, "_blank")}
                    outlined
                  />
                ))
              ) : (
                <div className="flex h-3rem align-items-center">
                  No attachments
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Private Checkbox Row */}
        <div className="col-12">
          <div className="flex align-items-center gap-2 mt-2">
            <label className="font-semibold" htmlFor="private">
              Private:
            </label>
            <div id="private" className="flex align-items-center">
              {submittal.private ? "Yes" : "No"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
