import { InputText } from "primereact/inputtext";
import { fetchVendors } from "../../api/vendorsAPI";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { fetchSpecs } from "../../api/specsAPI";
import { fetchEntities } from "../../api/entitiesAPI";
import { getUserData } from "../../util/storage";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import { getNextRfiNumber } from "../../api/rfisAPI";
import { RFIGeneralInformationLoader } from "../Loaders/RFILoader";
import { createNewDate } from "../../util/helper";

const schedule_impact_data = [
  { label: "Low", value: "Low" },
  { label: "Medium", value: "Medium" },
  { label: "High", value: "High" },
];

const statusOptions = [
  { label: "Open", value: "open" },
  { label: "Closed", value: "closed" },
  { label: "Draft", value: "draft" },
];

export default function RFIGeneralInformation({
  rfi,
  setRfi,
  editMode,
  isPending,
}) {
  const isProcoreUser = getUserData()["is_procore_user"];
  const { data: specs, isFetching: isFetchingSpecs } = useQuery({
    queryKey: ["specs"],
    queryFn: fetchSpecs,
    staleTime: 5 * 60 * 1000,
  });

  const { data: vendors, isFetching: isFetchingVendors } = useQuery({
    queryKey: ["vendors"],
    queryFn: fetchVendors,
    staleTime: 5 * 60 * 1000,
  });

  const { data: users, isFetching: isFetchingUsers } = useQuery({
    queryKey: ["entities"],
    queryFn: fetchEntities,
    staleTime: 5 * 60 * 1000,
  });

  const {
    data: nextRfiNumber,
    refetch: fetchNextRfiNumber,
    isFetching: isFetchingNextRfiNumber,
  } = useQuery({
    queryKey: ["nextRfiNumber"],
    queryFn: getNextRfiNumber,
    enabled: false,
  });

  // Effects remain the same
  useEffect(() => {
    let _rfi = { ...rfi };
    if (!rfi.rfi_manager_id) {
      const user = getUserData();
      _rfi.rfi_manager_id = isProcoreUser ? user.procore_user.id : user.id;
    }
    setRfi(_rfi);
    fetchNextRfiNumber();
  }, []);

  useEffect(() => {
    if (nextRfiNumber && !editMode) {
      let _rfi = { ...rfi };
      _rfi.number = nextRfiNumber;
      setRfi(_rfi);
    }
  }, [nextRfiNumber]);

  useEffect(() => {
    if (rfi.received_from_id && users) {
      users.forEach((element) => {
        if (element.id === rfi.received_from_id && element.vendor.id) {
          let _rfi = { ...rfi };
          _rfi.responsible_contractor_id = element.vendor.id;
          setRfi(_rfi);
        }
      });
    }
  }, [rfi.received_from_id]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _rfi = { ...rfi };
    _rfi[`${name}`] = val;
    setRfi(_rfi);
  };

  const onCheckBoxChanged = (e) => {
    const val = e.checked;
    let _rfi = { ...rfi };
    _rfi["private"] = val;
    setRfi(_rfi);
  };

  if (isPending || isFetchingSpecs || isFetchingVendors || isFetchingUsers) {
    return <RFIGeneralInformationLoader />;
  }

  return (
    <div className="flex flex-column">
      <div className="grid">
        {/* First Row */}
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <label htmlFor="number">RFI Number</label>
            <InputText
              id="number"
              type="number"
              value={rfi.number}
              onChange={(e) => onInputChange(e, "number")}
              disabled={isFetchingNextRfiNumber}
              className="w-full"
            />
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <label htmlFor="status">Status</label>
            <Dropdown
              id="status"
              value={rfi.status} // Bind to rfi.status
              onChange={(e) => onInputChange(e, "status")} // Update rfi on change
              options={statusOptions} // Options for dropdown
              placeholder="Select Status"
              className="w-full"
            />
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <label htmlFor="due-date">Due Date</label>
            <Calendar
              id="due-date"
              value={rfi?.due_date && createNewDate(rfi?.due_date)}
              onChange={(e) => onInputChange(e, "due_date")}
              showIcon
              className="w-full"
            />
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <label htmlFor="rfi-manager">RFI Manager</label>
            <Dropdown
              id="rfi-manager"
              value={rfi.rfi_manager_id}
              onChange={(e) => onInputChange(e, "rfi_manager_id")}
              options={users}
              optionLabel="name"
              optionValue="id"
              placeholder="Select a Person"
              className="w-full"
              disabled={isFetchingUsers}
              filter
            />
          </div>
        </div>

        {/* Second Row */}
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <label htmlFor="received-from">Received From</label>
            <Dropdown
              id="received-from"
              value={rfi.received_from_id}
              onChange={(e) => onInputChange(e, "received_from_id")}
              options={users}
              optionLabel="name"
              optionValue="id"
              placeholder="Select a Person"
              className="w-full"
              disabled={isFetchingUsers}
              filter
            />
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <label htmlFor="ball-in-court">Ball In Court</label>
            <div className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3">
              {rfi?.ball_in_court
                ? rfi.ball_in_court.map((user) => user.name).join(",")
                : "--"}
            </div>
            <small className="text-xs">
              Will be automatically assigned after creating the RFI
            </small>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <label htmlFor="responsible-contractor">
              Responsible Contractor
            </label>
            <Dropdown
              id="responsible-contractor"
              value={rfi.responsible_contractor_id}
              onChange={(e) => onInputChange(e, "responsible_contractor_id")}
              options={vendors}
              optionLabel="name"
              optionValue={isProcoreUser ? "id" : "_id"}
              placeholder="Select a Vendor"
              className="w-full"
              disabled={true}
              filter
            />
            <small className="text-xs">
              Will be automatically selected after selecting received from
            </small>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <label htmlFor="specification-section">Spec Section</label>
            <Dropdown
              id="specification-section"
              value={rfi.specification_section_id}
              onChange={(e) => onInputChange(e, "specification_section_id")}
              options={specs}
              optionLabel="label"
              optionValue="id"
              placeholder="Select a Spec Section"
              className="w-full"
              disabled={isFetchingSpecs}
              filter
            />
          </div>
        </div>

        {/* Third Row */}
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <label htmlFor="created-at">Sent On</label>
            <div className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3">
              {new Date(
                rfi?.created_at && createNewDate(rfi?.created_at)
              ).toLocaleDateString()}
            </div>
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <label htmlFor="drawing-number">Drawing Number</label>
            <InputText
              id="drawing-number"
              value={rfi.drawing_number}
              onChange={(e) => onInputChange(e, "drawing_number")}
              className="w-full"
            />
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <label className="font-semibold" htmlFor="date-initiated">
              {isProcoreUser ? "Date Initiated" : "Response Delay Time"}
            </label>
            <div
              id="date-initiated"
              className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
            >
              {isProcoreUser
                ? "--"
                : rfi.response_delay_time
                ? rfi.response_delay_time
                : "--"}
            </div>{" "}
          </div>
        </div>
        <div className="col-12 md:col-3">
          <div className="flex flex-column gap-2">
            <label htmlFor="schedule-impact">Schedule Impact</label>
            <Dropdown
              id="schedule-impact"
              value={rfi.schedule_impact}
              onChange={(e) => onInputChange(e, "schedule_impact")}
              options={schedule_impact_data}
              placeholder="Select"
              className="w-full"
            />
          </div>
        </div>

        {/* Fourth Row */}
        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <label htmlFor="assignee-ids">Assignees</label>
            <MultiSelect
              id="assignee-ids"
              value={rfi.assignee_ids}
              onChange={(e) => onInputChange(e, "assignee_ids")}
              options={users}
              optionLabel="name"
              optionValue="id"
              placeholder="Select People"
              className="w-full"
              filter
            />
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="flex flex-column gap-2">
            <label htmlFor="distribution-ids">Distribution List</label>
            <MultiSelect
              id="distribution-ids"
              value={rfi.distribution_ids}
              onChange={(e) => onInputChange(e, "distribution_ids")}
              options={users}
              optionLabel="name"
              optionValue="id"
              placeholder="Select People"
              className="w-full"
              filter
            />
          </div>
        </div>

        {/* Fifth Row */}
        <div className="col-12">
          <div className="flex align-items-center gap-2 mt-3">
            <Checkbox
              inputId="private"
              onChange={onCheckBoxChanged}
              checked={rfi.private}
            />
            <label htmlFor="private" className="text-sm">
              Private - Visible only to admins, workflow, and distribution list
              members
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
