import { Fieldset } from "primereact/fieldset";
import RFIRequest from "../components/RFIs/RFIRequest";
import { useContext, useEffect, useState } from "react";
import RFIGeneralInformation from "../components/RFIs/RFIGeneralInformation";
import { AppContext } from "../context/AppProvider";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createRfi } from "../api/rfisAPI";
import { queryClient } from "../api/requestProcessor";
import { getErrorMessage } from "../util/helper";
import { uploadFiles } from "../api/filesAPI";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { fetchTeamMembers } from "../api/teamAPI";

export default function NewRFIPage() {
  const [rfi, setRfi] = useState({
    subject: "",
    rfi_body: "",
    attachments: [],
    number: 0,
  });
  const [files, setFiles] = useState([]);
  const [uploadReset, setUploadReset] = useState(false);
  const [label, setLabel] = useState("Save");

  const { toast, user } = useContext(AppContext);
  const navigate = useNavigate();

  const isProcoreUser = user.is_procore_user;

  const { data: teamMembers, isFetching } = useQuery({
    queryKey: ["teamMembers"],
    queryFn: fetchTeamMembers,
    staleTime: 5 * 60 * 1000,
  });

  const { mutate: createRfiMutation, isPending: isPendingCreation } =
    useMutation({
      mutationFn: createRfi,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["rfis"] });
        navigate("/rfis");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "RFI Created",
          life: 3000,
        });
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  const { mutateAsync: uploadFilesMutation, isPending: isPendingUpload } =
    useMutation({
      mutationFn: uploadFiles,
      onSuccess: () => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "File Uploaded",
          life: 3000,
        });
        setUploadReset(true);
      },
      onError: (error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: getErrorMessage(error),
          life: 3000,
        });
      },
    });

  useEffect(() => {
    if (isPendingUpload) {
      setLabel("Uploading Files");
    } else if (isPendingCreation) {
      setLabel("Saving Changes");
    } else {
      setLabel("Create and Send Emails");
    }
  }, [isPendingUpload, isPendingCreation, isProcoreUser]);

  useEffect(() => {
    if (teamMembers) {
      setRfi((prev) => ({ ...prev, distribution_ids: teamMembers }));
    }
  }, [teamMembers]);

  const cancelRfiCreation = () => {
    navigate("/rfis");
  };

  const printErrorMessage = (errorMessage) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMessage,
      life: 3000,
    });
  };

  const checkValidation = () => {
    if (!rfi.subject) {
      printErrorMessage("Please specify a subject area for your request.");
      return false;
    } else if (!rfi.rfi_body) {
      printErrorMessage(
        "Please provide details about your request in the question section."
      );
      return false;
    } else if (!rfi.assignee_ids || rfi.assignee_ids.length < 1) {
      printErrorMessage("Please select an assignee.");
      return false;
    }
    return true;
  };

  const handleCreateRfi = async () => {
    if (checkValidation()) {
      let updatedAttachments = [];

      if (files.length > 0) {
        const uploadPromises = files.map(async (file) => {
          return await uploadFilesMutation({
            files: [file],
          });
        });

        const responses = await Promise.all(uploadPromises);
        updatedAttachments = [...responses];
      }

      const rfiData = { ...rfi };

      createRfiMutation({
        rfiData: rfiData,
        attachments: updatedAttachments,
      });
    }
  };

  return (
    <>
      <div className="card">
        <Fieldset legend="Create New RFI">
          <div>
            <RFIRequest
              rfi={rfi}
              setRfi={setRfi}
              setFiles={setFiles}
              uploadReset={uploadReset}
              setUploadReset={setUploadReset}
              isProcoreUser={isProcoreUser}
              isPending={false}
            />
            <RFIGeneralInformation
              rfi={rfi}
              setRfi={setRfi}
              editMode={false}
              isPending={false}
            />
          </div>
          <div className="flex justify-content-end gap-2 mt-2">
            <Button
              label="Cancel"
              icon="pi pi-times"
              outlined
              onClick={cancelRfiCreation}
            />
            <Button
              label={label}
              icon="pi pi-check"
              onClick={handleCreateRfi}
              loading={isPendingCreation || isPendingUpload}
              disabled={isPendingCreation || isPendingUpload}
              className="right-0"
            />
          </div>
        </Fieldset>
      </div>
    </>
  );
}
