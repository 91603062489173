import React from "react";
import { RFIGeneralInformationLoader } from "../Loaders/RFILoader";
import { createNewDate } from "../../util/helper";

export default function ViewRFIGeneralInformation({
  rfi,
  isProcoreUser,
  isPending,
}) {
  if (isPending) {
    return <RFIGeneralInformationLoader />;
  }

  if (rfi.id) {
    return (
      <div className="flex flex-column">
        <div className="grid">
          {/* First Row */}
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="number">
                RFI Number
              </label>
              <div
                id="number"
                className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
              >
                {rfi.number}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="status">
                Status
              </label>
              <div
                id="status"
                className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
              >
                {rfi?.status}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="due-date">
                Due Date
              </label>
              <div
                id="due-date"
                className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
              >
                {rfi.due_date}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="rfi-manager">
                RFI Manager
              </label>
              <div
                id="rfi-manager"
                className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
              >
                {rfi.rfi_manager?.name}
              </div>
            </div>
          </div>

          {/* Second Row */}
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="received-from">
                Received From
              </label>
              <div
                id="received-from"
                className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
              >
                {rfi.received_from?.name}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="ball-in-court">
                Ball In Court
              </label>
              <div
                id="ball-in-court"
                className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
              >
                {isProcoreUser
                  ? rfi.ball_in_courts?.map((user) => user.name).join(", ")
                  : rfi.ball_in_court?.map((user) => user.name).join(", ")}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="responsible-contractor">
                Responsible Contractor
              </label>
              <div
                id="responsible-contractor"
                className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
              >
                {rfi.responsible_contractor?.name}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="specification-section">
                Spec Section
              </label>
              <div
                id="specification-section"
                className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
              >
                {rfi.specification_section?.label}
              </div>
            </div>
          </div>

          {/* Third Row */}
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="created-at">
                Sent On
              </label>
              <div
                id="created-at"
                className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
              >
                {new Date(
                  rfi?.created_at && createNewDate(rfi?.created_at)
                ).toLocaleDateString()}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="drawing-number">
                Drawing Number
              </label>
              <div
                id="drawing-number"
                className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
              >
                {rfi.drawing_number}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="date-initiated">
                {isProcoreUser ? "Date Initiated" : "Response Delay Time"}
              </label>
              <div
                id="date-initiated"
                className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
              >
                {isProcoreUser
                  ? "--"
                  : rfi.response_delay_time
                  ? rfi.response_delay_time
                  : "--"}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="schedule-impact">
                Schedule Impact
              </label>
              <div
                id="schedule-impact"
                className="flex h-3rem align-items-center border-1 surface-border border-round surface-100 px-3"
              >
                {isProcoreUser
                  ? rfi.schedule_impact?.status
                  : rfi.schedule_impact}
              </div>
            </div>
          </div>

          {/* Fourth Row */}
          <div className="col-12 md:col-6">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="assignee-ids">
                Assignees
              </label>
              <div
                id="assignee-ids"
                className="flex h-auto min-h-3rem align-items-start border-1 surface-border border-round surface-100 p-3"
              >
                {rfi.assignees?.length > 0 ? (
                  rfi.assignees.map((user, index) => (
                    <React.Fragment key={user.id}>
                      <span>{user.name}</span>
                      {index !== rfi.assignees.length - 1 && <br />}
                    </React.Fragment>
                  ))
                ) : (
                  <span>--</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6">
            <div className="flex flex-column gap-2">
              <label className="font-semibold" htmlFor="distribution-ids">
                Distribution List
              </label>
              <div
                id="distribution-ids"
                className="flex flex-wrap gap-2 h-auto min-h-3rem align-items-center border-1 surface-border border-round surface-100 p-3"
              >
                {rfi.distribution_list?.length > 0 ? (
                  rfi.distribution_list.map((user, index) => (
                    <React.Fragment key={user.id}>
                      <span>{user.name}</span>
                      {index !== rfi.distribution_list.length - 1 && <br />}
                    </React.Fragment>
                  ))
                ) : (
                  <span>--</span>
                )}
              </div>
            </div>
          </div>

          {/* Fifth Row */}
          <div className="col-12">
            <div className="flex align-items-center gap-2 mt-3">
              <div className="flex align-items-center gap-2">
                <div className="font-semibold">Private:</div>
                <span>{rfi.private ? "Yes" : "No"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
